import styled from 'styled-components';
import { WEB_NAME } from 'src/constants/layout';
import _ from 'lodash';
import dynamic from 'next/dynamic';
import { DATALAYER_TYPE, sendDataLayerGlobal } from 'src/utils/datalayer/send-datalayer';
import { useRouter } from 'next/router';
import { SHOW_COMMEMORATE } from 'src/constants';

const WidgetFollow = dynamic(import('src/components/widgets/widget-follow'));

const Footer = ({ data }: any) => {
  const router = useRouter();

  // const [footer, setFooter] = useState('');
  // useEffect(() => {
  //   (async () => {
  //     try {
  //       const resMenu = await GET('/api/footer');
  //       !_.isEmpty(resMenu) && setFooter(resMenu);
  //     } catch (err) {
  //       console.error('Footer', err);
  //     }
  //   })();
  // }, []);
  return (
    <>
      <FooterWrapper className='footer footer-wrapper'>
        <section className='container' style={{ filter: SHOW_COMMEMORATE === 'true' ? 'grayscale(1)' : 'unset' }}>
          <div className='row footer-separator'>
            <div className='col-12 col-lg-3 footer footer-left'>
              {/* ------------------------------------ CATEGORIES - 1 ------------------------------- */}

              {!_.isEmpty(data) && (
                <ul className='first-ul'>
                  {data?.slice(0, 1).map((item: any, index: any) => (
                    <li key={index}>
                      <a
                        className='item-main'
                        href={`/${item.link || '#'}`}
                        aria-label={item?.nameTh}
                        title={item?.nameTh}
                        //! DATALAYER
                        onClick={() =>
                          sendDataLayerGlobal({
                            type: DATALAYER_TYPE.TRACK,
                            router: router?.pathname,
                            section: 'footer',
                            data: {
                              block: '1',
                              title: item?.nameTh,
                              heading: item?.nameTh
                            }
                          })
                        }
                      >
                        {item?.nameTh}
                      </a>
                      <div className='footer-sub'>
                        {item?.sub?.map((itemSub: any, indexSub: any) => (
                          <div key={indexSub}>
                            <a
                              href={itemSub?.link}
                              aria-label={itemSub?.nameEng}
                              title={itemSub?.nameEng}
                              //! DATALAYER
                              onClick={() =>
                                sendDataLayerGlobal({
                                  type: DATALAYER_TYPE.TRACK,
                                  router: router?.pathname,
                                  section: 'footer',
                                  data: {
                                    block: '1',
                                    title: itemSub?.nameTh,
                                    heading: `${item?.nameTh}:${itemSub?.nameTh}`
                                  }
                                })
                              }
                            >
                              {itemSub?.nameTh}
                            </a>
                          </div>
                        ))}
                      </div>
                    </li>
                  ))}
                </ul>
              )}
              {!_.isEmpty(_.find(data, { nameTh: 'Collaboration' })) && (
                <ul>
                  {[_.find(data, { nameTh: 'Collaboration' })].map((item: any, index: any) => (
                    <li key={index}>
                      <a
                        className='item-main'
                        href={`/${item.link || '#'}`}
                        aria-label={item?.nameTh}
                        title={item?.nameTh}
                        //! DATALAYER
                        onClick={() =>
                          sendDataLayerGlobal({
                            type: DATALAYER_TYPE.TRACK,
                            router: router?.pathname,
                            section: 'footer',
                            data: {
                              block: '1',
                              title: item?.nameTh,
                              heading: item?.nameTh
                            }
                          })
                        }
                      >
                        {item?.nameTh}
                      </a>
                      <div className='footer-sub'>
                        {item?.sub?.map((itemSub: any, indexSub: any) => (
                          <div key={indexSub}>
                            <a
                              href={itemSub?.link}
                              aria-label={itemSub?.nameEng}
                              title={itemSub?.nameEng}
                              //! DATALAYER
                              onClick={() =>
                                sendDataLayerGlobal({
                                  type: DATALAYER_TYPE.TRACK,
                                  router: router?.pathname,
                                  section: 'footer',
                                  data: {
                                    block: '1',
                                    title: itemSub?.nameTh,
                                    heading: `${item?.nameTh}:${itemSub?.nameTh}`
                                  }
                                })
                              }
                            >
                              {itemSub?.nameTh}
                            </a>
                          </div>
                        ))}
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            {/* ------------------------------------ CATEGORIES - 2 ------------------------------- */}
            {!_.isEmpty(data) && (
              <div className='col-12 col-lg-3 col-md-12 footer footer-center'>
                <ul>
                  {data?.slice(12, 13).map((item: any, index: any) => (
                    <li key={index}>
                      <a
                        className='item-main'
                        href={`/${item.link || '#'}`}
                        aria-label={item.nameTh}
                        title={item.nameTh}
                        //! DATALAYER
                        onClick={() =>
                          sendDataLayerGlobal({
                            type: DATALAYER_TYPE.TRACK,
                            router: router?.pathname,
                            section: 'footer',
                            data: {
                              block: '2',
                              title: item?.nameTh,
                              heading: item?.nameTh
                            }
                          })
                        }
                      >
                        {item.nameTh}
                      </a>
                      <div className='footer-sub'>
                        {item?.sub?.map((itemSub: any, indexSub: any) => (
                          <div>
                            {(itemSub?.id === 11 || itemSub?.id === 12 || itemSub?.id === 88) && (
                              <div key={indexSub}>
                                <a
                                  href={itemSub?.link}
                                  aria-label={itemSub.nameTh}
                                  title={itemSub.nameTh}
                                  //! DATALAYER
                                  onClick={() =>
                                    sendDataLayerGlobal({
                                      type: DATALAYER_TYPE.TRACK,
                                      router: router?.pathname,
                                      section: 'footer',
                                      data: {
                                        block: '2',
                                        title: itemSub?.nameTh,
                                        heading: `${item?.nameTh}:${itemSub?.nameTh}`
                                      }
                                    })
                                  }
                                >
                                  {itemSub?.nameTh}
                                </a>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            {/* ------------------------------------ CATEGORIES - 3 ------------------------------- */}
            {!_.isEmpty(data) && (
              <div className='col-12 col-lg-3 col-md-12 footer footer-right'>
                <ul>
                  {data?.slice(13, 14).map((item: any, index: any) => (
                    <li key={index}>
                      <a
                        className='item-main'
                        href={`/${item.link || '#'}`}
                        aria-label={item.nameTh}
                        title={item.nameTh}
                        //! DATALAYER
                        onClick={() =>
                          sendDataLayerGlobal({
                            type: DATALAYER_TYPE.TRACK,
                            router: router?.pathname,
                            section: 'footer',
                            data: {
                              block: '3',
                              title: item?.nameTh,
                              heading: item?.nameTh
                            }
                          })
                        }
                      >
                        {item.nameTh}
                      </a>
                      <div className='footer-sub'>
                        {item?.sub?.map((itemSub: any, indexSub: any) => (
                          <div key={indexSub}>
                            <a
                              href={itemSub?.link}
                              aria-label={itemSub.nameTh}
                              title={itemSub.nameTh}
                              //! DATALAYER
                              onClick={() =>
                                sendDataLayerGlobal({
                                  type: DATALAYER_TYPE.TRACK,
                                  router: router?.pathname,
                                  section: 'footer',
                                  data: {
                                    block: '3',
                                    title: itemSub?.nameTh,
                                    heading: `${item?.nameTh}:${itemSub?.nameTh}`
                                  }
                                })
                              }
                            >
                              {itemSub?.nameTh}
                            </a>
                          </div>
                        ))}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className='col-12 col-lg-3 col-md-12 footer footer-right about'>
              <ul>
                {/* {data?.slice(2, 3).map((item: any, index: any) => ( */}
                <li>
                  <a
                    className='item-main'
                    href={'/about-us'}
                    aria-label='about-us'
                    title='about-us'
                    //! DATALAYER
                    onClick={() =>
                      sendDataLayerGlobal({
                        type: DATALAYER_TYPE.TRACK,
                        router: router?.pathname,
                        section: 'footer',
                        data: {
                          block: '4',
                          title: 'about',
                          heading: 'about'
                        }
                      })
                    }
                  >
                    about
                  </a>
                  <div className='footer-sub'>
                    <a href='/about-us'>about us</a>
                  </div>
                </li>
                {/* ))} */}
              </ul>
            </div>
          </div>
          {/* --------------------------------- SOCIAL --------------------------------- */}
          <div className='footer-bottom'>
            <div className='widget-socail'>
              <WidgetFollow
                //! DATALAYER
                eventDataLayer={{
                  type: DATALAYER_TYPE.TRACK_POSITION,
                  router: router?.pathname,
                  section: 'follow',
                  position: 'on:footer'
                }}
              />
            </div>
            <span>
              {`Ⓒ ${new Date().getFullYear()} -`} <p> {WEB_NAME} </p> {'All Rights Reserved.'}{' '}
            </span>
            <div id='truehits_div'></div>
          </div>
        </section>
      </FooterWrapper>
    </>
  );
};

const FooterWrapper = styled.footer`
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-transform: uppercase;
  .footer {
    &.footer-right {
      &.about {
        p {
          transition: all 0.2s ease-in-out;
          font-size: 12px;
          color: #0000008c;
          text-align: left;

          br {
            display: none !important;
          }
          &:nth-child(1) {
            display: none;
          }
          &:nth-child(2) {
            margin-bottom: 15px;
          }
          &:nth-child(odd) {
            margin-bottom: 15px;
          }
        }
      }
    }
    @media (max-width: 768px) {
      margin-top: 15px;
    }
  }
  .footer-separator {
    text-align: center;
    .first-ul {
      margin-bottom: 10px;
    }
    ul {
      list-style: none;
      .item-main {
        font-size: 30px;
        border-bottom: 2px solid #ed1a3b;
        transition: all 0.2s ease-in-out;
        &:hover {
          color: #ed1a3b;
        }
      }
      .footer-sub {
        margin-top: 15px;
        a {
          transition: all 0.2s ease-in-out;
          font-size: 12px;
          color: #0000008c;
          padding: 15px 0;
          &:hover {
            color: #ed1a3b;
          }
        }
      }
    }
  }
  .footer-bottom {
    margin-top: 30px;
    .widget-socail {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    span {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      p {
        padding: 0 5px;
      }
    }
  }
`;
export default Footer;
